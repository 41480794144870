import { Drawer, WithLoader } from "@packages/theme-mui-v5";
import { useQuery } from "@packages/service-api";
import useLockProductBalance from "../../hooks/product-balances/useLockProductBalance";
import { getMonths } from "@packages/utils";

const LockProductBalances = () => {
  const { product, date = "" } = useQuery<{
    product: string;
    tab: string;
    date: string;
    month: string;
    year: string;
  }>();

  const [dailyYear, dailyMonth] = date.split("-");
  const monthList = getMonths("long");
  const selectedMonth = Number(dailyMonth);
  const selectedYear = Number(dailyYear);
  const selectedLastDate = `${monthList[selectedMonth - 1]} ${new Date(
    selectedYear,
    selectedMonth,
    0
  ).getDate()}, ${selectedYear}`;

  const { lockProductBalance, loading } = useLockProductBalance(
    product,
    selectedMonth,
    selectedYear
  );

  const handleSave = async () => {
    try {
      await lockProductBalance();
      onClose();
    } catch (error) {
      onClose();
    }
  };

  const onClose = () => {
    window.history.back();
  };

  return (
    <Drawer
      title="Lock Product Balance"
      onClose={onClose}
      loading={loading}
      actions={[
        { text: "Lock", action: handleSave, disabled: loading },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={loading}>
        <div>
          Are you sure you want to <b>lock</b> all records for this product up to{" "}
          <b>{`${selectedLastDate}`}</b>?
        </div>
      </WithLoader>
    </Drawer>
  );
};

export default LockProductBalances;
