import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { lazy } from "react";
import { SpinningLoader } from "@packages/theme-mui-v5";
import ApplicationAccessManagement from "@packages/application-access-management";
import EditProductBalance from "./drawers/product-balances/EditProductBalance";
import LockProductBalances from "./drawers/product-balances/LockProductBalances";
import UnLockProductBalances from "./drawers/product-balances/UnLockProductBalances";
import appConfig from "./config";
import { ViewSheets, ViewSheet } from "@packages/list-of-values";
import UnLockAllProductBalances from "./drawers/product-balances/UnLockAllProductBalances";
import LockAllProductBalances from "./drawers/product-balances/LockAllProductBalances";
// Nodes
const Nodes = SpinningLoader(lazy(() => import("./pages/Nodes/Nodes")));
const FormulaEditorContainer = SpinningLoader(
  lazy(() => import("./pages/Nodes/formula/FormulaEditorContainer"))
);
const CreateNode = SpinningLoader(lazy(() => import("./drawers/nodes/CreateNode")));
const EditNode = SpinningLoader(lazy(() => import("./drawers/nodes/EditNode")));
const DeleteNode = SpinningLoader(lazy(() => import("./drawers/nodes/DeleteNode")));

// Product
const Products = SpinningLoader(lazy(() => import("./pages/Products/Products")));
const CreateProduct = SpinningLoader(lazy(() => import("./drawers/products/CreateProduct")));
const DeleteProduct = SpinningLoader(lazy(() => import("./drawers/products/DeleteProduct")));
const EditProduct = SpinningLoader(lazy(() => import("./drawers/products/EditProduct")));
const ProductDependencies = SpinningLoader(
  lazy(() => import("./drawers/products/ProductDependencies"))
);
// Product Balances
const ProductBalances = SpinningLoader(lazy(() => import("./pages/ProductBalances")));

// Manual Reprocess
const ManualReprocess = SpinningLoader(lazy(() => import("./pages/ManualReprocess")));

// Site Settings
const EditSiteSettings = SpinningLoader(
  lazy(() => import("./drawers/site-settings/EditSiteSettings"))
);

// Reports
const Reports = SpinningLoader(lazy(() => import("./pages/Reports")));

// CSV Templates
const CsvTemplates = SpinningLoader(lazy(() => import("./pages/CsvTemplates")));

const AddEditCsvTemplate = SpinningLoader(
  lazy(() => import("./drawers/csv-templates/AddEditCsvTemplate"))
);

const DeleteCsvTemplate = SpinningLoader(
  lazy(() => import("./drawers/csv-templates/DeleteCsvTemplate"))
);

const GenerateReport = SpinningLoader(lazy(() => import("./drawers/csv-templates/GenerateReport")));

const basePath = "/material-balance";

const Router = () => {
  return (
    <BrowserRouter basename={basePath}>
      <Routes>
        <Route path="products" element={<Products />}>
          <Route path=":id/dependencies" element={<ProductDependencies />} />
          <Route path="create" element={<CreateProduct />} />
          <Route path=":id/delete" element={<DeleteProduct />} />
          <Route path=":id/edit" element={<EditProduct />} />
          <Route path=":id/view" element={<EditProduct readOnly />} />
        </Route>

        <Route
          path="sheets"
          element={
            <ViewSheets appConfig={appConfig} permissions={Object.values(appConfig.permissions)} />
          }
        />
        <Route
          path="sheets/:idOrKey"
          element={
            <ViewSheet
              returnPath="/sheets"
              applicationId={appConfig.applicationId}
              permissions={Object.values(appConfig.permissions)}
            />
          }
        />

        <Route path="products/:id/nodes" element={<Nodes />}>
          <Route path="create" element={<CreateNode />} />
          <Route path=":nodeId/edit" element={<EditNode />} />
          <Route path=":nodeId/delete" element={<DeleteNode />} />
        </Route>
        <Route path="products/:id/nodes">
          <Route path=":nodeId/formula" element={<FormulaEditorContainer />} />
        </Route>
        <Route path="product-balances" element={<ProductBalances />}>
          <Route
            path=":id/balances/:productBalanceId/nodes/:nodeId"
            element={<EditProductBalance />}
          />
          <Route path="lock" element={<LockProductBalances />} />
          <Route path="unlock" element={<UnLockProductBalances />} />
          <Route path="lockAll" element={<LockAllProductBalances />} />
          <Route path="unlockAll" element={<UnLockAllProductBalances />} />
        </Route>
        <Route path="manual-reprocess" element={<ManualReprocess />} />
        <Route path="reports" element={<Reports />} />
        <Route path="settings" element={<EditSiteSettings />} />
        <Route path="csv-templates" element={<CsvTemplates />}>
          <Route path="create" element={<AddEditCsvTemplate />} />
          <Route path=":csvTemplateId/delete" element={<DeleteCsvTemplate />} />
          <Route path=":csvTemplateId/generate-report" element={<GenerateReport />} />
          <Route path=":csvTemplateId/edit" element={<AddEditCsvTemplate />} />
        </Route>
        <Route
          path="admin/*"
          element={
            <ApplicationAccessManagement
              basePath={basePath}
              applicationId="4be661f8-400e-49cc-b7d9-cd2354e2e2ad"
            />
          }
        />
        <Route path="*" element={<Navigate to="/product-balances" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
