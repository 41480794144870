import { Box, Drawer, WithLoader } from "@packages/theme-mui-v5";
import { useQuery } from "@packages/service-api";
import useLockAllProductBalance from "../../hooks/product-balances/useLockAllProductBalance";
import { getMonths } from "@packages/utils";

const LockAllProductBalances = () => {
  const { date = "" } = useQuery<{
    product: string;
    tab: string;
    date: string;
    month: string;
    year: string;
  }>();

  const [dailyYear, dailyMonth] = date.split("-");
  const monthList = getMonths("long");
  const selectedMonth = Number(dailyMonth);
  const selectedYear = Number(dailyYear);
  const selectedLastDate = `${monthList[selectedMonth - 1]} ${new Date(
    selectedYear,
    selectedMonth,
    0
  ).getDate()}, ${selectedYear}`;

  const { lockAllProductBalance, loading, processingResults, isSuccess } = useLockAllProductBalance(
    selectedMonth,
    selectedYear
  );

  const handleSave = async () => {
    try {
      await lockAllProductBalance();
    } catch (error) {
      onClose();
    }
  };

  const onClose = () => {
    window.history.back();
  };

  const actions = isSuccess
    ? [{ text: "Close", action: onClose }]
    : [
        { text: "Lock", action: handleSave, disabled: loading },
        { text: "Cancel", action: onClose }
      ];

  return (
    <Drawer
      title="Lock All Product Balance"
      disableBackdropClick
      onClose={onClose}
      loading={loading}
      actions={actions}
    >
      <WithLoader loading={loading}>
        <div>
          Are you sure you want to <b>Lock All Products Records</b> up to{" "}
          <b>{`${selectedLastDate}`}</b>?
        </div>
        {isSuccess && (
          <div>
            <h4>Processing Results</h4>

            {Object.entries(processingResults).map(([key, value]) => (
              <Box
                key={key}
                sx={{
                  backgroundColor: "#e9e9e9;",
                  marginBottom: "12px",
                  padding: "5px",
                  fontSize: "14px"
                }}
              >
                {`${value}`}
              </Box>
            ))}
          </div>
        )}
      </WithLoader>
    </Drawer>
  );
};

export default LockAllProductBalances;
