import { Drawer, WithLoader } from "@packages/theme-mui-v5";
import useUnLockProductBalance from "../../hooks/product-balances/useUnLockProductBalance";
import { useQuery } from "@packages/service-api";
import { getMonths } from "@packages/utils";

const LockProductBalances = () => {
  const { product, date = "" } = useQuery<{
    product: string;
    tab: string;
    date: string;
    month: string;
    year: string;
  }>();

  const [dailyYear, dailyMonth] = date.split("-");
  const monthList = getMonths("long");
  const selectedMonth = Number(dailyMonth);
  const selectedYear = Number(dailyYear);
  const startDate = `${monthList[selectedMonth - 1]} ${new Date(
    selectedYear,
    selectedMonth,
    1
  ).getDate()}, ${selectedYear}`;

  const today = new Date();
  today.setDate(0);
  const endDate = `${monthList[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()}`;

  const { unLockProductBalance, loading } = useUnLockProductBalance(
    product,
    selectedMonth,
    selectedYear
  );
  const handleSave = async () => {
    try {
      await unLockProductBalance();
      onClose();
    } catch (error) {
      onClose();
    }
  };

  const onClose = () => {
    window.history.back();
  };

  return (
    <Drawer
      title="Unlock Product Balance"
      onClose={onClose}
      loading={loading}
      actions={[
        { text: "Unlock", action: handleSave, disabled: loading },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={loading}>
        <div>
          Are you sure you want to <b>unlock</b> all records for this product from{" "}
          <b>{`${startDate}`}</b> through <b>{`${endDate}`}</b>?
        </div>
      </WithLoader>
    </Drawer>
  );
};

export default LockProductBalances;
